<template>
  <div class="finance-account">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      @selection-change="handleSelectionChange"
    >
      <div slot="header" class="finance-account-table-header">
        <h3>温馨提示</h3>
        <div class="mb-1 line">1、结算账户是平台方用来与供应商进行资金结算时使用，提供打款账号，方便平台方线下为供应商打款。</div>
        <div class="mb-1 line">2、系统提供银行转账和支付宝两种结算账户类型。</div>
        <div class="line">3、只能添加与已认证供应商主体名称一致的结算账户，请准确填写，以免造成资金损失。</div>
      </div>

      <template slot="toolbar">
        <div class="col toolbar-title">
          提现账户列表
        </div>
        <div class="col-auto">
          <el-button @click="txShow = true;isEdit = false;" type="primary" size="small">添加提现账户</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" type="index" width="80"></el-table-column>
        <el-table-column label="账户类型">
          <template slot-scope="scope">{{ scope.row.account_type | account_type_filter }}</template>
        </el-table-column>
        <el-table-column label="开户名">
          <template slot-scope="scope">{{ scope.row.shop_name }}</template>
        </el-table-column>
        <el-table-column label="账号">
          <template slot-scope="scope">
            <span>{{ scope.row.account_num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行">
          <template slot-scope="scope">
            <span v-if="scope.row.out_type==1">{{ scope.row.bank }}</span>
            <span v-else>支付宝账户</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="edit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteMeal(scope.row,scope.$index)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :page-size="tableData.page_size"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--修改添加结算账户-->
    <el-dialog :title="tit" :visible.sync="txShow" width="600px" @close="cancel">
      <div class="addMealForm">
        <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
          <el-form-item label="请选择提现方式" prop="out_type" v-if="!isEdit">
            <el-radio-group v-model="txInfo.out_type" @change="a2a2Change">
              <el-radio :label="1">银行卡</el-radio>
              <el-radio :label="2">支付宝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="w200" label="开户名">开户名为供应商认证的主体名称，若需更改主体名称，请重新认证</el-form-item>
          <el-form-item :label="txInfo.out_type == 1 ? '银行卡号' : '支付宝账号'" prop="account_num">
            <el-input v-if="txInfo.out_type == 1" @change="check_num()" style="width: 300px;" placeholder="请输入银行卡号"
                      v-model="txInfo.account_num"></el-input>
            <el-input v-else style="width: 300px;" placeholder="请输入支付宝账号" v-model="txInfo.account_num"></el-input>
          </el-form-item>
          <el-form-item v-if="txInfo.out_type == 1" label="开户行" prop="bank">
            <el-input style="width: 300px;" placeholder="请输入开户行名称" v-model="txInfo.bank"></el-input>
          </el-form-item>
          <!-- <el-form-item v-else label="支付宝账号：" prop="alipayno">
            <el-input @change="alipaynoChange()" style="width: 300px;" placeholder="请输入支付宝账号" v-model="txInfo.account_num"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="cancel()" size="small">取消</el-button>
        <el-button type="primary" @click="accountSubmit()" size="small">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import * as API_logistics from '@/api/expressCompany';
import {handleDownload} from '@/utils';
import {Foundation} from '@/../ui-utils';
import {RegExp} from '@/../ui-utils'
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: {EnTableLayout},
  data() {
    const checkAccountNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`${this.txInfo.out_type === 1 ? '银行卡号' : '支付宝账号'}不能为空`))
      } else {
        callback()
      }
      // bug1087去掉账号位数限制
      // setTimeout(() => {
      //   if (!RegExp.bankNum.test(value) && this.txInfo.out_type === 1) {
      //     callback(new Error('请输入16-24正整数'))
      //   } else {
      //     callback()
      //   }
      // }, 1000)
    }
    return {
      tit: '添加/修改账户',
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      txInfo: {
        out_type: 1,
        account_num: ''
      },
      txRules: {
        a2a2: [
          {
            required: true,
            message: '请选择提现方式',
            trigger: 'change'
          }
        ],
        b2b2: [{
          required: true,
          message: '开户名不得为空',
          trigger: 'blur'
        }],
        account_num: [{
          required: true,
          validator: checkAccountNum,
          trigger: 'blur'
        }],
        bank: [
          {
            required: true,
            message: '请输入开户行名称',
            trigger: 'blur'
          }
        ],
        alipayno: [{
          required: true,
          message: '请输入支付宝账号',
          trigger: 'blur'
        }]
      },
      list1: [
        {
          value: 1,
          label: '选项1'
        }
      ],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },

      daterangeDate: '',

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: [],

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      isEdit: false
    };
  },
  filters: {
    account_type_filter: value => {
      switch (value) {
        case 0:
          return '前端会员';
        case 1:
          return '商家';
        case 2:
          return '供应商';
        case 3:
          return '企业';
        case 4:
          return '大平台';
      }
    }
  },
  activated() {
    this.GET_AccountList();
  },
  mounted() {
    this.GET_AccountList();
  },

  methods: {
    cancel() {
      this.txShow = false;
      this.txInfo = {
        out_type: 1
      };
    },
    alipaynoChange() {
      this.txInfo.account_num = this.txInfo.alipayno
    },
    check_num() {
      this.txInfo.account_num = this.txInfo.account_num.replace(/[^\d]/g, '');
      this.txInfo.account_num = this.txInfo.account_num.length > 24 ? this.txInfo.account_num.substring(0, 24) : this.txInfo.account_num
    },
    edit(d) {
      this.txShow = true;
      this.txInfo = Foundation.deepClone(d);
      this.isEdit = true;
    },
    addAccount() {
      let params = this.txInfo;
      API_account.addAccount(params).then(res => {
        console.log(res, 'resss');
        this.GET_AccountList();
      });
    },
    editAccount() {
      let params = this.txInfo;
      API_account.editAccount(params).then(res => {
        console.log(res, '修改完的值是');
        this.GET_AccountList();
      });
    },
    a2a2Change() {
      this.$refs.txInfo.clearValidate();
    },
    accountSubmit() {
      var self = this;
      this.$refs.txInfo.validate(valid => {
        if (valid) {
          if (this.txInfo.id) {
            self.editAccount();
          } else {
            self.addAccount();
          }
          this.txShow = false;
        }
      });
    },
    daterangeDateChange(val) {
      this.params.start_time = val ? val[0] / 1000 : '';
      this.params.end_time = val ? val[1] / 1000 : '';
    },
    /** 删除账户 */
    handleDeleteMeal(row, index) {
      this.$confirm('确认删除此账户, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        // const _ids = [row.goods_id].toString()
        // API_goods.deleteGoods(_ids).then(() => {
        console.log(row, 'row');
        API_account.deleteAccount(row.id).then(res => {
          console.log(res, '删除情况');
          this.tableData.data.splice(index, 1);
          this.$message.success('删除账户成功！');
        });

        // })
      });
    },
    submitImport() {
      this.importLoading = true;
      API_account.getSettleOrderList({}).then(response => {
        let nameLabel =
          this.shopInfo.shop_type != 2 ? '供应商名称' : '店铺名称';
        let nameValue =
          this.shopInfo.shop_type != 2 ? 'seller_name' : 'shop_name';
        let priceValue =
          this.shopInfo.shop_type == 2 ? 'order_price' : 'shop_order_price';
        let pointValue =
          this.shopInfo.shop_type == 2 ? 'consume_point' : 'shop_consume_point';
        response.forEach(item => {
          item.complete_time = Foundation.unixToDate(
            item.complete_time,
            'yyyy-MM-dd hh:mm'
          );
          if (item[pointValue]) {
            item[priceValue] =
              item[priceValue] + ' + ' + item[pointValue] + '积分';
          }
        });
        let tHeaders = [
          nameLabel,
          '订单号',
          '买家会员号',
          '订单金额',
          '快递单号',
          '快递名称',
          '订单日期'
        ];
        let filterVals = [
          nameValue,
          'sn',
          'member_name',
          priceValue,
          'ship_no',
          'logi_name',
          'complete_time'
        ];
        handleDownload(response, tHeaders, filterVals, '结算订单列表');
        this.importLoading = false;
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.GET_AccountList();
    },

    GET_AccountList() {
      this.loading = true;
      let params = this.params;
      API_account.getAccountList(params).then(response => {
        this.loading = false;
        console.log(response, '获得的结算账户数据');
        this.tableData = response;
      });
    },

    handleSelectionChange(val) {
      let ids = [];
      val.forEach(item => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement() {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type
        }).then(res => {
          this.$message.success('发起结算成功');
          this.GET_AccountList();
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods(row) {
      this.$router.push('/order/detail/' + row.sn);
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}
</style>
